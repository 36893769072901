<template>
  <form @submit.prevent="submit()" class="form-login" >
 
      <div class="card">
        <div class="card-header text-center">
          <h3 class="mb-0"><b>Login</b></h3>
        </div>

          <div class="card-body">
            <div class="form-group">
            <input         
              v-model="form.email"
              class="form-control"
              placeholder="Login"
            />
          </div>

          <div class="form-group">
            <input
              type="password"
              placeholder="Senha"
              class="form-control"
              v-model="form.password"
            />
          </div>

          <button class="btn btn-success w-100" :disabled='loading'  >
            <template v-if="loading">
              Entrando
              <i class="fa fa-spinner fa-spin"></i>
            </template>

            <template v-else>
              Entrar
                <i class="fa fa-sign-in-alt"></i>
            </template>
        </button>

        </div>
      </div>
  </form>
</template>

<script>

import { mapActions } from 'vuex'

export default {
  data: () => ({
    form: {
          email: "",
          password: "",
    },
      loading: false
  }),
  methods: {
    
    ...mapActions('auth', ['ActionDoLogin']),
    
    async submit () {

        this.loading = true

      try {
        
        //converte o Json do form para UrlParams para ser aceito pelo keycloak
      //  const newForm = new URLSearchParams(this.form);
        await this.ActionDoLogin(this.form)

        //chama a rota da pagina inicial
        this.$router.push({ name: 'home' })

      } catch (error) {
          //FALTA PERSONALIZAR O LOGIN MOSTRANDO A MENSAGEM PARA USUARIO CASO NAO CONSIGA LOGAR
        // console.log(error.data.error)
             alert('Usuario ou Senha Incorreto!')
             this.$router.push({ name: 'login' })
      }
        this.loading = false  
    }
  }
}
</script>

<style lang="scss" scoped>

  .form-login {

        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        h1{
          font-size:18pt;
        }

      .card {
        width:25%;
        color:  var(--verde-escuro-ems);
      }

  }
</style>